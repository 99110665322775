import Podcast from '@/models/podcasts/podcast'
import CustomParameter from '@/models/podcasts/CustomParameters'

export default {
  SET_PODCAST: (state, data) => {
    const podcast = new Podcast()
    podcast.fromData(data)
    state.podcast = podcast
  },
  ADD_PODCAST: (state, data) => state.podcasts.unshift(data),
  SET_PODCASTS: (state, data) => { state.podcasts = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  SET_CATEGORIES: (state, data) => { state.categories = data },
  SET_SUBCATEGORIES: (state, data) => { state.subcategories = data },
  SET_AUDIOS: (state, data) => { state.audios = data },
  SET_PODCAST_AUDIOS: (state, data) => { state.podcastAudios = data },
  CLEAR_PODCASTS: state => { state.podcasts = [] },
  SET_SORT_TYPES: (state, data) => { state.podcastSortTypes = data },
  SET_AUDIOS_PAGINATION: (state, data) => { state.audiosPagination = data },
  SET_PAGINATED_PODCAST_AUDIOS: (state, data) => { state.paginatedPodcastAudios = data },
  SET_PODCAST_PAGINATION: (state, data) => { state.podcastPagination = data },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  LOADING_PODCAST(state, value) {
    state.loadingPodcast = value
  },
  UPADTING_PODCAST(state, value) {
    state.updatingPodcast = value
  },
  UPDATE_PAGINATION: (state, data) => {
    state.pagination[data.propKey] = data.value
  },
  UPDATE_PODCAST: (state, data) => {
    const podcastIndex = state.podcasts.findIndex(p => p.id === data.id)
    Object.assign(state.podcasts[podcastIndex], data)
  },
  SET_TITLE: (state, data) => { state.podcast.title = data },
  SET_DESCRIPTION: (state, data) => { state.podcast.description = data },
  SET_AUTHOR: (state, data) => { state.podcast.author = data },
  ADD_CUSTOM_PARAMETER: state => { const ncp = new CustomParameter(); state.audio.customParameters.push(ncp) },
  REMOVE_CUSTOM_PARAMETER: (state, index) => { state.audio.customParameters.splice(index, 1) },
  UPDATE_CUSTOM_PARAMETER_KEY: (state, data) => { state.audio.customParameters[data.index].key = data.key },
  UPDATE_CUSTOM_PARAMETER_VALUE: (state, data) => { state.audio.customParameters[data.index].value = data.value },
  REMOVE_PODCASTS_BY_ID: (state, data) => {
    data.forEach(d => {
      state.podcasts.splice(state.podcasts.findIndex(x => x.mediaId === d), 1)
    })
  },
  RESET_PODCAST(state) {
    state.podcast = new Podcast()
  },
  SET_THUMBNAIL_URL: (state, data) => { state.podcast.thumbnailUrl = data },
  UPDATE_PODCAST_PROP: (state, { key, value }) => {
    state.podcast[key] = value
  },

  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },

  UPDATE_ELEMENT_FILTER: (state, data) => {
    state.filtersOfAudio.params[data.propKey] = data.value
  },
  UPDATE_ELEMENT_FILTER_PODCAST: (state, data) => {
    state.filtersOfPodcast.params[data.propKey] = data.value
  },
  RESET_ELEMENT_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
        onlyReady: true,
        onlyPublishedAudios: true,
      },
    }
    state.filtersOfAudio = filteri
  },
  RESET_PODCAST_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 20,
        search: '',
      },
    }
    state.filtersOfPodcast = filteri
  },

  // Audio Analytics
  SET_ANALYTICS(state, payload) {
    const { result, intervalType, differenceInDays } = payload
    state.intervalType = intervalType
    state.diffInTimeCustomDate = differenceInDays
    state.podcastAnalytics = result
    state.podcastAnalyticsGropuedData = {
      result: result?.graphResult?.map(entry => entry?.result),
      date: result?.graphResult?.map(entry => entry?.date),
    }
    state.podcastAnalyticsTotal = result?.total
  },

  SET_LIVE_VIEWERS(state, data) {
    state.liveViewers = data?.liveViewers
    state.liveViewersGroupedData = {
      uniqueViewers: data?.liveViewers?.map(entry => entry?.uniqueViewers),
      date: data?.liveViewers?.map(entry => entry?.date),
    }
    state.liveViewersTotal = data?.total
  },
  LOADING_CUSTOM_ANALYTICS(state, value) {
    state.loadingCustomAnalytics = value
  },

  SET_KEY_MOMENTS_CARDS(state, data) {
    state.keyMomentsCards = data
  },
  SET_CUSTOM_DATE(state, { e, dateStr }) {
    state.customDate = e
    state.dateStr = dateStr
  },
  DESTROY_CUSTOM_DATE(state) {
    state.customDate = []
    state.dateStr = ''
  },
  DESTROY_PODCAST_ANALYTICS(state) {
    state.podcastAnalytics = []
    state.keyMoments = []
    state.keyMomentsCards = []
    state.topCountries = []
    state.topDevices = []
    state.topBrowsers = []
    state.podcastEpisodes = []
    state.listenEpisode = []
    state.podcastAnalyticsGropuedData = {}
    state.keyMomentsGroupedData = {}
    state.listenEpisodeGroupedData = {}
    state.podcastAnalyticsGropuedData = {}
    state.liveViewersGroupedData = {}
    state.topCountriesGroupedData = {}
    state.topDevicesGroupedData = {}
    state.topBrowsersGroupedData = {}
    state.podcastAnalyticsTotal = 0
    state.listenEpisodeTotal = 0
    state.liveViewersTotal = 0
  },
  SET_TOP_BROWSERS(state, data) {
    state.topBrowsers = data
    state.topBrowsersGroupedData = data.map(entry => ({
      names: entry?.browser,
      values: entry?.browserTypeNr,
    }))
  },
  LOADING_ANALYTICS(state, value) {
    state.loadingAnalytics = value
  },
  LOADING_ANALYTICS_TOP_COUNTRIES(state, value) {
    state.loadingTopCountries = value
  },
  LOADING_ANALYTICS_TOP_BROWSERS(state, value) {
    state.loadingTopBrowsers = value
  },
  LOADING_ANALYTICS_TOP_DEVICES(state, value) {
    state.loadingTopDevices = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS(state, value) {
    state.loadingKeyMoments = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS_CARDS(state, value) {
    state.loadingKeyMomentsCards = value
  },
  LOADING_ANALYTICS_LIVE_VIEWERS(state, value) {
    state.loadingLiveViewers = value
  },
  SET_TOP_COUNTRIES(state, data) {
    state.topCountries = data
    state.topCountriesGroupedData = data.map(entry => ({
      names: entry?.countryName,
      values: entry?.countryNr,
    }))
  },
  SET_TOP_DEVICES(state, data) {
    state.topDevices = data
    state.topDevicesGroupedData = {
      deviceType: data.map(entry => entry?.deviceType),
      deviceTypeNr: data.map(entry => entry?.deviceTypeNr),
    }
  },
  LOADING_ANALYTICS_EPISODES(state, val) {
    state.loadingEpisodes = val
  },
  SET_EPISODES(state, data) {
    state.podcastEpisodes = data
  },
  SET_LISTEN_EPISODE(state, jsonData) {
    if (jsonData && jsonData.listensPerAudio) {
      state.listenEpisodeGroupedData = Object.keys(jsonData.listensPerAudio).map(audioKey => {
        const listensData = jsonData.listensPerAudio[audioKey] || []
        const name = Object.keys(listensData)[0] || '' // Extract the audio title
        const data = listensData[name].map(item => item.listens).reverse() || []// Reverse the 'listens' array
        const date = listensData[name].map(item => item.date).reverse() || [] // Reverse the 'date' array
        return {
          name,
          data,
          date,
        }
      })
      state.listenEpisode = jsonData
      state.listenEpisodeTotal = jsonData.total
    }
  },
  LOADING_ANALYTICS_LISTEN_EPISODE(state, val) {
    state.loadingListenEpisode = val
  },
  LOADING_ANALYTICS_REPORT(state, value) {
    state.loadingAnalyticsReport = value
  },
  SET_ANALYTICS_REPORT(state, data) {
    state.analyticsReport = data
  },
}
