import moment from 'moment'

const helperMixin = {
  methods: {
    /**
 * Generates aggregated data based on the provided parameters.
 * @param {Object} chartData - Object containing result and date arrays.
 * @param {number} resultsLength - The desired length of the results.
 * @returns {Object} - Object containing aggregated results and dates.
 */
    aggregatedData(chartData, resultsLength, rawData, intervalType, mediaType) {
      const resultData = chartData.result || []
      const dates = chartData.date || []
      let allDates = []
      let result = []

      if (resultsLength === 1 && mediaType === 2) {
        return this.fillMissingHours(rawData.graphResult)
      }
      if (intervalType === 1 || intervalType === 2 || intervalType === 3 || intervalType === 4) {
        const today = new Date()
        const beforeEndDate = new Date(today)
        const olderDates = this.generateOlderDates(beforeEndDate, resultsLength)

        // Initialize result and allDates arrays
        result = Array(resultsLength).fill(0)
        allDates = olderDates

        // Populate result array based on chartData
        if (chartData.date && chartData.result && chartData.date.length > 0 && chartData.result.length > 0) {
          chartData.date.forEach((date, index) => {
          // Find the index of the date in olderDates array
            const dateIndex = olderDates.findIndex(oldDate => moment(date).isSame(moment(oldDate), 'day'))

            // If the date exists in olderDates, update the result array
            if (dateIndex !== -1) {
              result[dateIndex] = chartData.result[index]
            }
          })
        }
      } else if (intervalType === 6 || intervalType === 7 || intervalType === 8 || intervalType === 0) {
        const frequencyMapping = {
          0: { frequency: 48, interval: 30 },
          6: { frequency: 30, interval: 1 },
          7: { frequency: 36, interval: 5 },
          8: { frequency: 24, interval: 15 },
        }
        const { frequency, interval } = frequencyMapping[intervalType]
        return this.fillMissing(chartData, frequency, interval)
      } else {
        const reversedResultData = [...resultData].reverse() // Create a copy and then reverse
        const reversedDates = [...dates].reverse() // Create a copy and then reverse
        result = reversedResultData
        allDates = reversedDates
      }
      // Return aggregated results and dates
      return { results: result, dates: allDates }
    },

    /**
 * Generates an array of older dates based on the provided parameters.
 * @param {Date} oneBeforeEndDate - The date before the end date from which to generate older dates.
 * @param {number} length - The desired length of the generated dates array.
 * @returns {string[]} - An array of older dates in the form of strings.
 */
    generateOlderDates(oneBeforeEndDate, length) {
      const generatedDates = []
      const currentDate = new Date(oneBeforeEndDate)

      generatedDates.push(currentDate.toDateString())

      // Loop to generate older dates based on the provided length
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < length; i++) {
        const date = new Date(currentDate) // Create a new date object for each iteration
        date.setDate(currentDate.getDate() - i) // Adjust the date based on the iteration
        generatedDates.unshift(date.toDateString()) // Add the date to the beginning of the array
      }

      // Returns an array of older dates including today
      return generatedDates
    },

    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate()
    },

    getIntervalDays(intervalType, currentYear) {
      const today = new Date()
      const currentMonth = today.getMonth()
      let totalDays = 0

      switch (intervalType) {
      case 0:
        totalDays = 1
        break
      case 1:
        totalDays = 8
        break
      case 2:
        totalDays = this.getDaysInMonth(currentMonth, currentYear)
        break
      case 3:
        totalDays = this.getDaysInMonth(currentMonth, currentYear) + this.getDaysInMonth(currentMonth - 1, currentYear)
        break
      case 4:
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 3; i++) {
          totalDays += this.getDaysInMonth(currentMonth - i, currentYear)
        }
        break
      default:
        totalDays = 0
      }
      return totalDays
    },

    convertTimeinUnits(timeInSeconds) {
      if (timeInSeconds === null || timeInSeconds === undefined) {
        return 'N/A'
      }

      if (timeInSeconds <= 0) {
        return 'Invalid input'
      }
      if (timeInSeconds >= 1 && timeInSeconds <= 59) {
        const value = `${(timeInSeconds / 60).toFixed(2)} min`
        const percentage = `${((timeInSeconds / 60) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 60 && timeInSeconds < 3600) {
        const value = `${(timeInSeconds / 3600).toFixed(2)} h`
        const percentage = `${((timeInSeconds / 3600) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 3600 && timeInSeconds < 86400) {
        const value = `${(timeInSeconds / 86400).toFixed(2)} d`
        const percentage = `${((timeInSeconds / 86400) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 86400 && timeInSeconds < 2592000) {
        const value = `${(timeInSeconds / 2592000).toFixed(2)} m`
        const percentage = `${((timeInSeconds / 2592000) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 2592000 && timeInSeconds < 31104000) {
        const value = `${(timeInSeconds / 31104000).toFixed(2)} y`
        const percentage = `${((timeInSeconds / 31104000) * 100).toFixed(2)} %`
        return { value, percentage }
      }
      return ''
    },

    formatedDate(date) {
      if (Array.isArray(date)) {
        return date.map(d => {
          if (d) {
            if (d.includes('ago')) {
              return d
            }
            return moment.utc(d).local().format('DD/MM/YYYY')
          }
          return 'No date set!'
        })
      }
      if (date) return moment.utc(date).local().format('DD/MM/YYYY')
      return 'No date set!'
    },

    fillMissingHours(data) {
      const currentDate = new Date()
      const currentDateTime = currentDate.getTime()

      const filledData = Array.from({ length: 25 }, (_, index) => {
        const hourDateUTC = new Date(currentDateTime - index * 60 * 60 * 1000)
        const hourIso = hourDateUTC.toISOString()

        return {
          result: 0,
          date: hourIso,
        }
      }).reverse()

      data.forEach(item => {
        const itemDateUTC = new Date(item.date).getTime()

        const minutesDiff = Math.floor((currentDateTime - itemDateUTC) / (1000 * 60))

        if (minutesDiff >= 0 && minutesDiff < 1499) {
          const dataIndex = 25 - Math.floor(minutesDiff / 60) - 1

          if (dataIndex >= 0 && dataIndex < 25) {
            filledData[dataIndex].result = item.result
          }
        }
      })
      const results = filledData.map(item => item.result)
      const dates = filledData.map(item => {
        const minutesDiff = Math.floor((currentDateTime - new Date(item.date).getTime()) / (1000 * 60))
        if (minutesDiff < 60) return 'Just now'
        if (minutesDiff < 1499) return `${Math.floor(minutesDiff / 60)} hours ago`
        return 'over 24 hours ago'
      })
      return { results, dates }
    },

    formatHoursAgo(date) {
      const currentDate = new Date()
      const timeDifference = currentDate - date
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60))

      if (hoursDifference < 1) return 'Just now'
      if (hoursDifference === 1) return '1 hour ago'
      if (hoursDifference >= 24) return `${hoursDifference} hours ago`
      return `${hoursDifference} hours ago`
    },

    fillMissingHoursAndMinutes(chartData, arrayLength, frequency) {
      const now = Date.now()
      const timeInterval = frequency * 60 * 1000

      const allTimestamps = Array.from({ length: arrayLength }, (_, i) => now - (arrayLength - i) * timeInterval)

      const formattedDates = allTimestamps.map(timestamp => {
        const diffMinutes = Math.floor((now - timestamp) / (1000 * 60))
        const hours = Math.floor(diffMinutes / 60)
        const minutes = diffMinutes % 60

        if (hours > 0) {
          return minutes > 0 ? `${hours}h ${minutes}m ago` : `${hours}h ago`
        }

        if (minutes > 0) {
          return `${minutes}m ago`
        }

        return 'Just now'
      })

      const results = Array(arrayLength).fill(0)

      chartData.forEach(({ date, result: value }) => {
        const dataTime = new Date(date).getTime()
        const index = allTimestamps.findIndex(timestamp => dataTime <= timestamp)

        if (index !== -1) {
          results[index] += value
        }
      })

      return { results, dates: formattedDates }
    },

    fillMissing(chartData, arrayLength, frequency) {
      const now = Date.now()
      const timeInterval = frequency * 60 * 1000

      const allTimestamps = Array.from({ length: arrayLength }, (_, i) => now - (arrayLength - i) * timeInterval)

      const formattedDates = allTimestamps.map(timestamp => {
        const diffMinutes = Math.floor((now - timestamp) / (1000 * 60))
        const hours = Math.floor(diffMinutes / 60)
        const minutes = diffMinutes % 60

        if (hours > 0) {
          return minutes > 0 ? `${hours}h ${minutes}m ago` : `${hours}h ago`
        }

        if (minutes > 0) {
          return `${minutes}m ago`
        }

        return 'Just now'
      })

      const results = [...chartData.result]

      return { results, dates: formattedDates }
    },
  },

}

export default helperMixin
