export default {
  ADD_PROJECT: (state, project) => state.projects.unshift(project),
  SET_PROJECT: (state, data) => { state.project = data },
  SET_PROJECT_ONEDIT: (state, data) => { state.projectOnEdit = data },
  PROJ_ID: (state, data) => { state.activeProjectId = data },
  SET_PROJECTS: (state, data) => { state.projects = data },
  SET_PINNED_PROJECTS: (state, data) => { state.pinnedProjects = data },
  SET_UNPINNED_PROJECTS: (state, data) => { state.unpinnedProjects = data },
  SET_PROJECTS_SELECT_LIST: (state, data) => {
    state.projects = data.map(x => ({
      id: x.value,
      name: x.name,
      publicProjectId: x.value,
    }))
  },
  SET_PROJECTS_OF_USER: (state, data) => { state.projectsOfUser = data },
  SET_ACTIVE_PROJECT: (state, data) => { state.activeProject = data; state.activeProjectId = data.publicProjectId },
  SET_ACTIVE_PROJECT_ID: (state, data) => { state.activeProjectId = data },
  SET_ACTIVE_PROJECT_PAGE: (state, data) => { state.activeProjectPage = data },
  SET_ORGANIZATION_ID: (state, data) => { state.userOrganization.id = data },
  SET_PROJECT_NAME: (state, data) => { state.activeProject.name = data },
  SET_PROJECT_BISKOID: (state, data) => { state.activeProject.biskoId = data },
  SET_PROJECT_PREBIDCONFIGID: (state, data) => { state.activeProject.prebidConfigId = data },
  SET_PROJECTONEDIT_NAME: (state, data) => { state.projectOnEdit.name = data },
  SET_PROJECTONEDIT_BISKOID: (state, data) => { state.projectOnEdit.biskoId = data },
  SET_PROJECTONEDIT_PREBIDCONFIGID: (state, data) => { state.projectOnEdit.prebidConfigId = data },
  SET_PROJECTONEDIT_CHROMECASTAPPLICATIONID: (state, data) => { state.projectOnEdit.chromeCastApplicationId = data },
  SET_PROJECTONEDIT_DRMLICENSEURL: (state, data) => { state.projectOnEdit.drmLicenseUrl = data },
  SET_PROJECTONEDIT_FPSCERTIFICATEURL: (state, data) => { state.projectOnEdit.fpsCertificateUrl = data },
  SET_PROJECTONEDIT_WIDEVINELICENSEURL: (state, data) => { state.projectOnEdit.widevineLicenseUrl = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  SET_PROJECTS_CUSTOMSCREENS: (state, data) => { state.customScreens = data },
  SET_ENCODING_TEMPLATES: (state, data) => { state.encodingTemplates = data },
  SET_PROJECTONEDIT_ENCODING_TEMPLATE_ID: (state, data) => { state.projectOnEdit.encodingTemplateId = data },
  SET_RELEASE_CHANNEL: (state, data) => { state.releaseChannel = data },
  SET_PROJECTONEDIT_RELEASE_CHANNEL_ID: (state, data) => { state.projectOnEdit.releaseChannelId = data },
  SET_PROJECTONEDIT_PLAYER_URL: (state, data) => { state.projectOnEdit.playerUrl = data },
  // SET_PROJECT_VIDEOCATALOG_URL: (state, data) => { state.videoCatalogUrl = data },
  SET_SUBTITLES_ENABLED_BY_DEFAULT: (state, data) => { state.projectOnEdit.subtitlesEnabledByDefault = data },

  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  UPDATE_IS_DROPDOWN_LOADING: (state, data) => { state.isDropdownLoading = data },
  UPDATE_PROJECTS_FOR_ACTIVE: (state, publicProjectId) => {
    state.projectsOfUser.map(x => {
      if (x.publicProjectId === publicProjectId) { x.isDefault = true; return x }
      x.isDefault = false; return x
    })
  },

  UPDATE_ACTIVE_PROJECT_PROP: (state, { key, value }) => { state.activeProject[key] = value },

  CLEAR_PROJECTS: state => { state.projects = [] },
  CLEAR_PROJECT: state => {
    state.project = {
      id: 0,
      name: '',
    }
  },
  CLEAR_ACTIVE_PROJECT: state => { state.activeProject = null; state.activeProjectId = ''; state.activeProjectPage = 1 },
  CLEAR_PROJECTONEDIT: state => {
    state.projectOnEdit = {
      organizationId: '',
      name: '',
    }
  },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] ??= data.value
      state.filters.params[data.propKey] = data.value
    }
  },
  UPDATE_DROPDOWN_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.dropdownFilters.params[data.propKey] = data.value
      state.dropdownFilters.params.page = '1'
    } else {
      state.dropdownFilters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        organizationId: state.filters.params.organizationId,
        page: 1,
        take: 10,
        search: '',
        order: 'asc',
      },
    }
    state.filters = filteri
  },
  SET_SIDEBAR_COLLAPSED: (state, isCollapsed) => {
    state.isSidebarCollapsed = isCollapsed
  },

}
