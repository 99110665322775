import helpers from '@/services/mixins/dataAggregation'

export default {
  getPublicId: state => state.liveVideo.publicId,
  getLiveVideo: state => state.liveVideo,
  getLiveVideoTitle: state => state.liveVideo.title,
  getLiveVideos: state => state.liveVideos,
  getLiveVideoThumbnail: state => state.liveVideo.thumbnail,
  getIsLoading: state => state.getIsLoading,
  getPagination: state => state.pagination,
  getPageLength: state => state.filters.params.take,
  getCurrentPage: state => state.filters.params.page,
  getLiveVideoCustomScreens: state => state.liveVideoCustomScreens,
  getCustomScreensSelectList: state => state.customScreensSelectList,
  getStartDateUtc: state => state.liveVideo.startDateUTC,
  getPublishDate: state => state.liveVideo.publishDate,
  getSecureLink: state => state.secureLink,
  getLiveVideoFullDate(state) {
    const start = state.liveVideo.startDate
    const end = state.liveVideo.endDate
    return `${start} to ${end}`
  },

  // Analytics
  getTopCountries: state => state.topCountries,
  getTopDevices: state => state.topDevices,
  getTopDevicesGroupedData: state => state.topDevicesGroupedData,
  getTopBrowsersGroupedData: state => state.topBrowsersGroupedData,
  getTopCountriesGroupedData: state => state.topCountriesGroupedData,
  getTotalClipsPagination: state => state.totalClipsPagination,
  getVideoAnalytics: state => state.videoAnalytics,
  getCustomDate: state => state.customDate,
  videoAnalyticsGropuedData: state => {
    const currentYear = new Date().getFullYear()
    let intervalDays = null
    const mediaType = 1 // Use 2 for videos and live-videos

    if (state.intervalType === 5) {
      intervalDays = Math.floor(state.diffInTimeCustomDate)
    } else {
      intervalDays = helpers.methods.getIntervalDays(state.intervalType, currentYear)
    }
    return helpers.methods.aggregatedData(state.videoAnalyticsGropuedData, intervalDays, state.videoAnalytics, state.intervalType, mediaType)
  },
  getVideoAnalyticsTotal: state => state.videoAnalyticsTotal,
  startDateUTC: state => state.liveVideo.startDateUTC,
  // Live Viewrs
  getVideoAnalyticsLiveViewrs: state => state.liveViewersGroupedData,
  getLiveViewrsTotal: state => state.liveViewersTotal,
  getVideoAnalyticsKeyMoments: state => state.keyMomentsGroupedData,
  getVideoAnalyticsKeyMomentsCards: state => state.keyMomentsCards,
  getLoadingPlayer: state => state.loadingPlayer,
}
