import helpers from '@/services/mixins/dataAggregation'

export default {
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
  getPodcast: state => state.podcast,
  getPodcasts: state => state.podcasts,
  getTitle: state => state.podcast.title,
  getLanguageId: state => state.languageId,
  podcastLoaded: state => state.loadingPodcast,
  podcastUpdated: state => state.updatingPodcast,
  getSortTypes: state => state.podcastSortTypes,
  getCategories: state => state.podcast.categories.map(({ id, name }) => ({ id, name })),
  getPublicId: state => state.podcast.publicId,
  getDescription: state => state.podcast.description,
  getAuthor: state => state.podcast.author,
  getLink: state => state.podcast.link,
  getRssFeedUrl: state => state.podcast.rssFeedUrl,
  getPodcastAudios: state => state.podcastAudios,
  getAudios: state => state.audios,
  getThumbnailUrl: state => state.podcast.thumbnailUrl,
  getThumbnailPath: state => state.podcast.getThumbnailPath,
  getAudiosPagination: state => state.audiosPagination,
  getPodcastPagination: state => state.podcastPagination,
  getPaginatedPodcastAudios: state => state.paginatedPodcastAudios,

  getSubcategories: state => state.podcast.categories.map(x => {
    if (x?.subcategories) return x.subcategories.map(sc => ({ ...sc, categoryId: x.id }))
    return []
  }).flat(),

  // Audio Analytics
  getTopCountries: state => state.topCountries,
  getTopDevices: state => state.topDevices,
  getTopDevicesGroupedData: state => state.topDevicesGroupedData,
  getTopCountriesGroupedData: state => state.topCountriesGroupedData,
  getPodcastAnalytics: state => state.podcastAnalytics,
  getCustomDate: state => state.customDate,
  podcastAnalyticsGropuedData: state => {
    const currentYear = new Date().getFullYear()
    let intervalDays = null
    const mediaType = 2 // Use 2 for audios and podcasts

    if (state.intervalType === 5) {
      intervalDays = Math.floor(state.diffInTimeCustomDate)
    } else {
      intervalDays = helpers.methods.getIntervalDays(state.intervalType, currentYear)
    }
    return helpers.methods.aggregatedData(state.podcastAnalyticsGropuedData, intervalDays, state.podcastAnalytics, state.intervalType, mediaType)
  },
  getPodcastAnalyticsTotal: state => state.podcastAnalyticsTotal,
  getListenEpisodeTotal: state => state.listenEpisodeTotal,
  getTopBrowsersGroupedData: state => state.topBrowsersGroupedData,
  getPodcastEpisodes: state => state.podcastEpisodes,
  getPodcastListenEpisode: state => state.listenEpisodeGroupedData,

  // Live Viewrs
  getPodcastAnalyticsLiveViewrs: state => state.liveViewersGroupedData,
  getLiveViewrsTotal: state => state.liveViewersTotal,

}
