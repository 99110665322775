import { replaceHash } from '@/services/mixins/playerMixins'

export default {
  getIsLoading: state => state.isLoading,
  getPlayers: state => state.players.options,
  getDefaultPlayers: state => state.defaultPlayers,
  getPlayersSelectList: state => state.playersSelectList,
  getPlayer: state => state.player,
  getOptions: state => state.options,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

  getPlayerFloatOnScroll: state => state.player.floatOnScroll,
  getPlayerFloatBorderWidth: state => state.player.floatBorderWidth,
  getPlayerFloatBorderColor: state => state.player.floatBorderColor,

  getPlayerFloatOnMobile: state => state.player.floatOnMobile,
  getPlayerFloatRequiresInteraction: state => state.player.floatRequiresInteraction,
  getPlayerFloatBorder: state => state.player.floatBorder,
  getPlayerSharing: state => state.player.sharing,
  getPlayerFloatDismissible: state => state.player.floatDismissible,
  getPlayerFloatWidth: state => state.player.floatWidth,

  getPlayerLogoOn: state => state.player.logoOn,
  getPlayerLogoUrl: state => state.player.logoUrl,
  getPlayerLogoPath: state => state.player.logoPath,
  getPlayerLogoDefaultOpacity: state => state.player.logoDefaultOpacity,
  getPlayerLogoInactiveOpacity: state => state.player.logoInactiveOpacity,
  getPlayerLogoPositionOptionId: state => state.player.logoPositionOptionId,
  getPlayerLogoOnClickUrl: state => state.player.logoOnClickUrl,

  getPlayerAdScheduleId: state => state.player.adScheduleId,
  getPlayerAdvertising: state => state.player.advertising,

  getPlayerReleaseChannel: state => state.player.releaseChannel,
  getAllPlayerReleaseChannels: state => state.player.releaseChannels,
  getPlayerPlayerSizeType: state => state.player.playerSizeType,
  getPlayerPreloadOption: state => state.player.preloadOption,
  getPlayerDefaultBandwidthEstimateOption: state => state.player.defaultBandwidthEstimateOption,
  getPlayerResponsiveSizeOption: state => state.player.responsiveSizeOption,
  getPlayerLogoPositionOption: state => state.player.logoPositionOption,
  getPlayerFloatPositionOption: state => state.player.floatPositionOption,
  getPlayerPlayerSocialNetworks: state => state.player.playerSocialNetworks,
  getPlayerPlayerSocialNetworkIds: state => state.player.socialNetworkIds,
  getConfig(state) {
    return {
      // config: {
      size: {
        sizeType: state.player.playerSizeType.name.toUpperCase(),
        aspectRatio: state.player.responsiveSizeOption.name,
        width: state.player.width,
        height: state.player.height,
      },
      autoplay: state.player.autoPlay,
      casting: state.player.casting,
      autostartOnLoad: {
        state: state.player.autoStartOnLoad,
        onMobile: state.player.autoStartOnLoadMobile,
        onData: state.player.autoStartOnLoadOnData,
      },
      autostartOnViewable: {
        state: state.player.autoStartOnViewableOn,
        onMobile: state.player.autoStartOnViewableMobile,
        onData: state.player.autoStartOnViewableOnData,
        percentageViewable: state.player.autoStartOnViewablePercentage,
      },
      autopauseOnViewable: {
        state: state.player.autoPauseOnViewableOn,
        percentageViewable: state.player.autoPauseOnViewablePercentage,
      },
      autopauseOnCuepoint: {
        state: state.player.autoPauseOnCuePointState,
        onDesktop: state.player.autoPauseOnCuePointOnDesktop,
        onMobile: state.player.autoPauseOnCuePointOnMobile,
        continueUnmuted: state.player.autoPauseOnCuePointContinueUnMuted,
        timeToPause: state.player.autoPauseOnCuePointTimeToPause,
        svgFile: state.player.autoPauseOnCuePointPlayButtonUrl,

      },
      pauseOtherVideos: state.player.pauseOtherVideos,
      focusOnAutostart: state.player.focusOnAutoStart,
      pauseOnRelatedShown: state.player.pauseWhenRelatedIsShown,
      volume: state.player.volume,
      muted: state.player.muteOnStart,
      debug: state.player.debug,
      adsRequireInteraction: state.player.adsRequireInteraction,
      loop: state.player.loopContent,
      highlightCurrentVideo: state.player.highlightCurrentVideo,
      allowSeekOnLive: state.player.allowSeekOnLive,
      seekLiveAfterPause: state.player.seekLiveAfterPause,
      onHoverPlay: state.player.onHoverPlay,
      socialMedia: state.player?.playerSocialNetworks.map(x => x.value),
      float: {
        state: state.player.floatOnScroll,
        onMobile: state.player.floatOnMobile,
        position: state.player.floatPositionOption.value,
        dismissible: state.player.floatDismissible,
        style: {
          width: state.player.floatWidth,
          border: `${state.player.floatBorderWidth}px solid ${state.player.floatBorderColor}`,
        },
        requiresInteraction: state.player.floatRequiresInteraction,
      },
      playbackRates: state.player.enablePlayBackRateControls ? [0.25, 0.5, 0.75, 1, 2, 4] : [],
      controls: {
        theaterButton: state.player.theaterButton,
        settingsButton: state.player.settingsButton,
        chromecastButton: state.player.chromeCastButton,
        nextButton: state.player.nextButton,
        airplayButton: state.player.airplayButton,
        bigPlaylButton: state.player.bigPlayButton,
        fullscreenButton: state.player.fullScreenButton,
        volumeButton: state.player.volumeButton,
        pictureInPictureButton: state.player.pictureInPictureButton,
        shareButton: state.player.shareButton,
        audioOnlyButton: state.player.audioOnlyButton,
        rewindButton: state.player.rewindButton,
        forwardButton: state.player.forwardButton,
      },
      logo: {
        state: state.player.logoOn,
        file: state.player.logoUrl,
        position: state.player.logoPositionOption.value,
        defaultOpacity: state.player.logoDefaultOpacity,
        inactiveOpacity: state.player.logoInactiveOpacity,
        onclickURL: state.player.logoOnClickUrl,
      },
      lang: {
        locale: 'en',
        controls: {
          play: state.player.Play,
          pause: state.player.Pause,
          next: state.player.Next,
          replay: state.player.Replay,
          volume: state.player.VolumeText,
          mute: state.player.Mute,
          unmute: state.player.UnMute,
          live: state.player.Live,
          settings: state.player.Settings,
          theater: state.player.Theater,
          fullscreen: state.player.FullScreen,
          chromecast: state.player.Chromecast,
          airplay: state.player.Airplay,
          pictureInPicture: state.player.PictureInPicture,
          skipIntro: state.player.SkipIntro,
          skipAd: state.player.Skipad,
          playlistTitle: state.player.ContinueWatching,
          related: state.player.Playlist,
          upNext: state.player.upNext,
          continueAfterPause: state.player.continueAfterPause,
          audioOnly: state.player.AudioOnly,
          videoMode: state.player.VideoMode,
          rewind: state.player.Rewind,
          forward: state.player.Forward,
        },
        settings: {
          quality: state.player.Quality,
          subtitles: state.player.Subtitles,
          autoplay: state.player.Autoplay,
          playbackRate: state.player.PlaybackRate,
          auto: state.player.Auto,
          off: state.player.Off,
          on: state.player.On,
          normal: state.player.Normal,
          share: state.player.Share,
          autoGeneratedSubtitles: state.player.AutoGeneratedSubtitles,
          refresh: state.player.Refresh,
        },
        ads: {
          ad: state.player.Ad,
          skip: state.player.Skip,
          skipIn: state.player.SkipIn,
          visit: state.player.Visit,
          info: state.player.Info,
          simultaneousAds: state.player.SimultaneousAds,
          adAnnouncement: state.player.AdAnnouncement,
        },
        messages: {
          playbackErrorTitle: state.player.PlaybackErrorTitle,
          playbackErrorDescription: state.player.PlaybackErrorDescription,
          geoBlockedTitle: state.player.GeoBlockedTitle,
          geoBlockedDescription: state.player.GeoBlockedDescription,
          streamInterruptedTitle: state.player.StreamInterruptedTitle,
          streamInterruptedDescription: state.player.StreamInterruptedDescription,
          streamStartingTitle: state.player.StreamStartingTitle,
          streamStartingDescription: state.player.StreamStartingDescription,
          unpublishedTitle: state.player.UnpublishedTitle,
          unpublishedDescription: state.player.UnpublishedDescription,
          privateTitle: state.player.PrivateTitle,
          privateDescription: state.player.PrivateDescription,
          paywallRestrictedHover: state.player.PaywallRestrictedHover,
          paywallRestrictedTitle: state.player.PaywallRestrictedTitle,
          paywallRestrictedDescription: state.player.PaywallRestrictedDescription,
          paywallRestrictedButton: state.player.PaywallRestrictedButton,
        },
      },
      skin: {
        controlBar: {
          background: state.player.controlBarBackgroundColor,
          spread: state.options?.controlBarSpreadOptions.find(x => x.id === state.player.controlBarSpreadOptionId)?.value,
          gradientMidPoint: state.player.controlBarGradientMidpoint,
          text: state.player.controlBarTextColor,
          icons: {
            default: state.player.controlBarIconsDefaultColor,
            hover: state.player.controlBarIconsHoverColor,
          },
          timeslider: {
            progress: state.player.controlBarTimesliderProgressColor,
            rail: state.player.controlBarTimesliderRailColor,
            buffer: state.player.controlBarTimesliderBufferColor,
            dragger: state.player.controlBarTimesliderDraggerColor,
          },
          volume: {
            dragger: state.player.controlBarVolumeDraggerColor,
            progress: state.player.controlBarVolumeProgressColor,
            rail: state.player.controlBarVolumeRailColor,
            notifier: state.player.controlBarVolumeNotifierColor,
          },
          timesliderOnAd: {
            progress: state.player.controlBarTimeSliderOnAdProgressColor,
            rail: state.player.controlBarTimeSliderOnAdRailColor,
            dragger: state.player.controlBarTimeSliderOnAdDraggerColor,
          },
        },
        menus: {
          background: {
            default: state.player.menuBackroundDefaultColor,
            hover: state.player.menuBackroundHoverColor,
          },
          links: {
            default: state.player.menuLinksDefaultColor,
            hover: state.player.menuLinksHoverColor,
          },
          autoplay: {
            autoplayOn: state.player.menuAutoplayOnColor,
            autoplayOff: state.player.menuAutoplayOffColor,
          },
        },
        nextVideo: {
          background: state.player.nextVideoBackgroundColor,
          text: state.player.nextVideoTextColor,
          timeslider: {
            rail: state.player.nextVideoTsRailColor,
            progress: state.player.nextVideoTsProgressColor,
          },
          icons: {
            play: {
              default: state.player.nextVideoIconsPlayDefaultColor,
              hover: state.player.nextVideoIconsPlayHoverColor,
            },
            close: state.player.nextVideoTsIconsCloseColor,
          },
        },
        playlist: {
          background: state.player.playlistBackgroundColor,
          text: state.player.playlistTextColor,
          icons: {
            arrows: {
              active: state.player.playlistIconsArrActiveColor,
              inactive: state.player.playlistIconsArrInactiveColor,
            },
            close: state.player.playlistIconsCloseColor,
          },
          card: {
            background: state.player.playlistCardBackgroundColor,
            title: state.player.playlistCardTitleColor,
            duration: {
              text: state.player.playlistCardDurationTextColor,
              background: state.player.playlistCardDurationBackgroundColor,
            },
            icons: {
              play: {
                default: state.player.playlistCardIconPlayDefaulColor,
                hover: state.player.playlistCardIconPlayHoverColor,
              },
            },
          },
        },
      },
      // },
    }
  },
  getEndpointObject(state) {
    const {
      controlBarTextColor,
      controlBarBackgroundColor,
      controlBarIconsDefaultColor,
      controlBarIconsHoverColor,
      controlBarTimesliderProgressColor,
      controlBarTimesliderRailColor,
      controlBarTimesliderDraggerColor,
      controlBarTimesliderBufferColor,
      controlBarTimeSliderOnAdProgressColor,
      controlBarTimeSliderOnAdRailColor,
      controlBarTimeSliderOnAdDraggerColor,
      controlBarVolumeProgressColor,
      controlBarVolumeRailColor,
      controlBarVolumeDraggerColor,
      controlBarVolumeNotifierColor,
      controlBarLoaderColor,
      menuBackroundDefaultColor,
      menuBackroundHoverColor,
      menuLinksDefaultColor,
      menuLinksHoverColor,
      menuAutoplayOnColor,
      menuAutoplayOffColor,
      playlistBackgroundColor,
      playlistTextColor,
      playlistIconsArrActiveColor,
      playlistIconsArrInactiveColor,
      playlistIconsCloseColor,
      playlistCardBackgroundColor,
      playlistCardTitleColor,
      playlistCardDurationTextColor,
      playlistCardDurationBackgroundColor,
      playlistCardIconPlayDefaulColor,
      playlistCardIconPlayHoverColor,
      nextVideoBackgroundColor,
      nextVideoTextColor,
      nextVideoTsRailColor,
      nextVideoTsProgressColor,
      nextVideoTsIconsCloseColor,
      nextVideoIconsPlayDefaultColor,
      nextVideoIconsPlayHoverColor,
      tooltipsTextColor,
      tooltipsBackroundColor,
      floatBorderColor,
      ...player
    } = state.player
    return {
      ...player,
      // controlBarDurationColor: playerMixins.default.methods.replaceHash(state.player.),
      controlBarTextColor: replaceHash(controlBarTextColor),
      controlBarBackgroundColor: replaceHash(controlBarBackgroundColor),
      controlBarIconsDefaultColor: replaceHash(controlBarIconsDefaultColor),
      controlBarIconsHoverColor: replaceHash(controlBarIconsHoverColor),
      controlBarTimesliderProgressColor: replaceHash(controlBarTimesliderProgressColor),
      controlBarTimesliderRailColor: replaceHash(controlBarTimesliderRailColor),
      controlBarTimesliderBufferColor: replaceHash(controlBarTimesliderBufferColor),
      controlBarTimesliderDraggerColor: replaceHash(controlBarTimesliderDraggerColor),
      controlBarTimesliderOnAdProgressColor: replaceHash(controlBarTimeSliderOnAdProgressColor),
      controlBarTimesliderOnAdRailColor: replaceHash(controlBarTimeSliderOnAdRailColor),
      controlBarTimesliderOnAdDraggerColor: replaceHash(controlBarTimeSliderOnAdDraggerColor),
      controlBarVolumeDraggerColor: replaceHash(controlBarVolumeDraggerColor),
      controlBarVolumeProgressColor: replaceHash(controlBarVolumeProgressColor),
      controlBarVolumeRailColor: replaceHash(controlBarVolumeRailColor),
      controlBarVolumeNotifierColor: replaceHash(controlBarVolumeNotifierColor),
      controlBarLoaderColor: replaceHash(controlBarLoaderColor),
      menuBackroundDefaultColor: replaceHash(menuBackroundDefaultColor),
      menuBackroundHoverColor: replaceHash(menuBackroundHoverColor),
      menuLinksDefaultColor: replaceHash(menuLinksDefaultColor),
      menuLinksHoverColor: replaceHash(menuLinksHoverColor),
      menuAutoplayOnColor: replaceHash(menuAutoplayOnColor),
      menuAutoplayOffColor: replaceHash(menuAutoplayOffColor),
      tooltipsTextColor: replaceHash(tooltipsTextColor),
      tooltipsBackroundColor: replaceHash(tooltipsBackroundColor),
      nextVideoBackgroundColor: replaceHash(nextVideoBackgroundColor),
      nextVideoTextColor: replaceHash(nextVideoTextColor),
      nextVideoTsRailColor: replaceHash(nextVideoTsRailColor),
      nextVideoTsProgressColor: replaceHash(nextVideoTsProgressColor),
      nextVideoTsIconsCloseColor: replaceHash(nextVideoTsIconsCloseColor),
      nextVideoIconsPlayDefaultColor: replaceHash(nextVideoIconsPlayDefaultColor),
      nextVideoIconsPlayHoverColor: replaceHash(nextVideoIconsPlayHoverColor),
      playlistBackgroundColor: replaceHash(playlistBackgroundColor),
      playlistTextColor: replaceHash(playlistTextColor),
      playlistCardTitleColor: replaceHash(playlistCardTitleColor),
      playlistCardBackgroundColor: replaceHash(playlistCardBackgroundColor),
      playlistCardDurationTextColor: replaceHash(playlistCardDurationTextColor),
      playlistCardDurationBackgroundColor: replaceHash(playlistCardDurationBackgroundColor),
      playlistCardIconPlayDefaulColor: replaceHash(playlistCardIconPlayDefaulColor),
      playlistCardIconPlayHoverColor: replaceHash(playlistCardIconPlayHoverColor),
      playlistIconsArrActiveColor: replaceHash(playlistIconsArrActiveColor),
      playlistIconsArrInactiveColor: replaceHash(playlistIconsArrInactiveColor),
      playlistIconsCloseColor: replaceHash(playlistIconsCloseColor),
      floatBorderColor: replaceHash(floatBorderColor),
    }
  },
  getCustomizeColorsObject(state) {
    return [
      {
        name: 'Control Bar',
        id: 'controlbar',
        elements: [
          {
            name: 'Text',
            colors: [
              {
                label: 'Default',
                prop: 'controlBarTextColor',
              },
            ],
          },
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'controlBarBackgroundColor',
                advanced: [
                  {
                    label: 'Spread',
                    type: 'select',
                    typeProperties: {
                      multiple: false,
                      clearable: false,
                      disabled: false,
                      label: 'name',
                      placeholder: 'Select a color spread type',
                      options: state.options.controlBarSpreadOptions,
                      reduce: option => option.id,
                    },
                    prop: 'controlBarSpreadOptionId',
                  },
                  // {
                  //   label: 'Gradient Midpoint',
                  //   type: 'slider',
                  //   typeProperties: {
                  //     adsorb: true,
                  //     tooltip: 'none',
                  //     interval: 2,
                  //   },
                  //   prop: 'controlBarGradientMidpoint',
                  // },
                ],
              },
            ],
          },
          {
            name: 'Icons',
            colors: [
              {
                label: 'Default',
                prop: 'controlBarIconsDefaultColor',
              },
              {
                label: 'Hover',
                prop: 'controlBarIconsHoverColor',
              },
            ],
          },
          {
            name: 'Timeslider',
            colors: [
              {
                label: 'Progress',
                prop: 'controlBarTimesliderProgressColor',
              },
              {
                label: 'Rail',
                prop: 'controlBarTimesliderRailColor',
              },
              {
                label: 'Buffer',
                prop: 'controlBarTimesliderBufferColor',
              },
              {
                label: 'Dragger',
                prop: 'controlBarTimesliderDraggerColor',
              },
            ],
          },
          {
            name: 'Timeslider On Ad',
            colors: [
              {
                label: 'Progress',
                prop: 'controlBarTimeSliderOnAdProgressColor',
              },
              {
                label: 'Rail',
                prop: 'controlBarTimeSliderOnAdRailColor',
              },
              {
                label: 'Dragger',
                prop: 'controlBarTimeSliderOnAdDraggerColor',
              },
            ],
          },
          {
            name: 'Volume',
            colors: [
              {
                label: 'Progress',
                prop: 'controlBarVolumeProgressColor',
              },
              {
                label: 'Rail',
                prop: 'controlBarVolumeRailColor',
              },
              {
                label: 'Dragger',
                prop: 'controlBarVolumeDraggerColor',
              },
              {
                label: 'Notifier',
                prop: 'controlBarVolumeNotifierColor',
              },
            ],
          },
          {
            name: 'Loader',
            hint: 'Mobile setting',
            colors: [
              {
                label: 'Default',
                prop: 'controlBarLoaderColor',
              },
            ],
          },
        ],
      },
      {
        name: 'Menus',
        id: 'menu',
        elements: [
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'menuBackroundDefaultColor',
              },
              {
                label: 'Hover',
                prop: 'menuBackroundHoverColor',
              },
            ],
          },
          {
            name: 'Links',
            colors: [
              {
                label: 'Default',
                prop: 'menuLinksDefaultColor',
              },
              {
                label: 'Hover',
                prop: 'menuLinksHoverColor',
              },
            ],
          },
          {
            name: 'Autoplay',
            colors: [
              {
                label: 'On',
                prop: 'menuAutoplayOnColor',
              },
              {
                label: 'Off',
                prop: 'menuAutoplayOffColor',
              },
            ],
          },
        ],
      },
      {
        name: 'Playlist',
        id: 'playlist',
        elements: [
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'playlistBackgroundColor',
              },
            ],
          },
          {
            name: 'Text',
            colors: [
              {
                label: 'Default',
                prop: 'playlistTextColor',
              },
            ],
          },
          {
            name: 'Icons',
            colors: [
              {
                label: 'Arrows Active',
                prop: 'playlistIconsArrActiveColor',
              },
              {
                label: 'Arrows Inactive',
                prop: 'playlistIconsArrInactiveColor',
              },
              {
                label: 'Close',
                prop: 'playlistIconsCloseColor',
              },
            ],
          },
          {
            name: 'Card',
            colors: [
              {
                label: 'Background',
                prop: 'playlistCardBackgroundColor',
              },
              {
                label: 'Title',
                prop: 'playlistCardTitleColor',
              },
              {
                label: 'Duration Text',
                prop: 'playlistCardDurationTextColor',
              },
              {
                label: 'Duration Background',
                prop: 'playlistCardDurationBackgroundColor',
              },
              {
                label: 'Icons Play Default',
                prop: 'playlistCardIconPlayDefaulColor',
              },
              {
                label: 'Icons Play Hover',
                prop: 'playlistCardIconPlayHoverColor',
              },
            ],
          },
        ],
      },
      {
        name: 'Next Video',
        id: 'nextVideo',
        elements: [
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'nextVideoBackgroundColor',
              },
            ],
          },
          {
            name: 'Text',
            colors: [
              {
                label: 'Default',
                prop: 'nextVideoTextColor',
              },
            ],
          },
          {
            name: 'Timeslider',
            colors: [
              {
                label: 'Rail',
                prop: 'nextVideoTsRailColor',
              },
              {
                label: 'Progress',
                prop: 'nextVideoTsProgressColor',
              },
            ],
          },
          {
            name: 'Icons',
            colors: [
              {
                label: 'Play Default',
                prop: 'nextVideoIconsPlayDefaultColor',
              },
              {
                label: 'Play Hover',
                prop: 'nextVideoIconsPlayHoverColor',
              },
              {
                label: 'Close',
                prop: 'nextVideoTsIconsCloseColor',
              },
            ],
          },
        ],
      },
      {
        name: 'Tooltips',
        id: 'tooltips',
        elements: [
          {
            name: 'Text',
            colors: [
              {
                label: 'Default',
                prop: 'tooltipsTextColor',
              },
            ],
          },
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'tooltipsBackroundColor',
              },
            ],
          },
        ],
      },
    ]
  },
  getCheckboxes() {
    return [
      {
        label: 'Show content title',
        prop: 'showContentTitle',
      },
      {
        label: 'Show content description',
        prop: 'showContentDescription',
      },
      {
        label: 'Enable Playback Rate Controls',
        prop: 'enablePlayBackRateControls',
      },
      {
        label: 'Show Theater button',
        setter: 'players/SET_PLAYER_THEATERBUTTON',
        prop: 'theaterButton',
      },
      {
        label: 'Show Settings button',
        prop: 'settingsButton',
      },
      {
        label: 'Show Chromecast button',
        prop: 'chromeCastButton',
      },
      {
        label: 'Show Next button',
        prop: 'nextButton',
      },
      {
        label: 'Show Airplay button',
        prop: 'airplayButton',
      },
      {
        label: 'Show Big Play button',
        prop: 'bigPlayButton',
      },
      {
        label: 'Show Full Screen button',
        prop: 'fullScreenButton',
      },
      {
        label: 'Show Volume button',
        prop: 'volumeButton',
      },
      {
        label: 'Show Picture in Picture button',
        prop: 'pictureInPictureButton',
      },
      {
        label: 'Show share button',
        prop: 'shareButton',
      },
      {
        label: 'Show live icon',
        prop: 'showLiveIcon',
      },
      {
        label: 'Show player controls during ads',
        prop: 'showPlayerControlsDuringVideoAdvertisement',
      },
      {
        label: 'Show auto play',
        prop: 'showAutoPlay',
      },
      {
        label: 'Show qualities',
        prop: 'showQualities',
      },
      {
        label: 'Show subtitles',
        prop: 'showSubtitles',
      },
      {
        label: 'Show helper button',
        prop: 'showHelperButton',
      },
      {
        label: 'Highlight current video',
        prop: 'highlightCurrentVideo',
      },
      {
        label: 'Allow seek on live',
        prop: 'allowSeekOnLive',
      },
      {
        label: 'Seek live after pause',
        prop: 'seekLiveAfterPause',
      },
      {
        label: 'Show audio only button',
        prop: 'audioOnlyButton',
      },
      {
        label: 'Show rewind button',
        prop: 'rewindButton',
      },
      {
        label: 'Show forward button',
        prop: 'forwardButton',
      },
    ]
  },
  getAdvancedSettingsMobileCheckboxes() {
    return [
      {
        label: 'Pause when related is shown',
        prop: 'pauseWhenRelatedIsShown',
      },
      {
        label: 'Read qualities',
        prop: 'readQualities',
      },
      {
        label: 'Enable gravity change',
        prop: 'enableGravityChange',
      },
      {
        label: 'Force sound',
        prop: 'forceSound',
      },
      {
        label: 'Enable haptic feedback on button click',
        prop: 'enableHapticFeedbackOnButtonClick',
      },
      {
        label: 'Enable haptic feedback on pinch to zoom',
        prop: 'enableHapticFeedbackOnPinchToZoom',
      },
    ]
  },

  getLocalizations() {
    return [
      {
        name: 'Player Controls',
        id: 'playerControls',
        items: [
          'Play',
          'Pause',
          'Next',
          'Replay',
          'VolumeText',
          'Mute',
          'UnMute',
          'Playlist',
          'FullScreen',
          'Theater',
          'PictureInPicture',
          'Chromecast',
          'Airplay',
          'Settings',
          'AudioOnly',
          'VideoMode',
          'Rewind',
          'Forward',
        ],
      },
      {
        name: 'More Player Options',
        id: 'morePlayerOptions',
        items: [
          'Autoplay',
          'PlaybackRate',
          'Normal',
          'Quality',
          'Auto',
          'On',
          'Off',
          'Subtitles',
          'AutoGeneratedSubtitles',
          'Share',
          'Refresh',
        ],
      },
      {
        name: 'Navigation Info',
        id: 'navigationInfo',
        items: [
          'Skip',
          'SkipIn',
          'SkipIntro',
          'Visit',
          'Info',
        ],
      },
      {
        name: 'Continue Watching & Up Next',
        id: 'continueWatching',
        items: [
          'ContinueWatching',
          'ContinueAfterPause',
          'UpNext',
          'NextVideoCancel',
          'PlayNow',
        ],
      },
      {
        name: 'Zoom',
        id: 'zoom',
        items: [
          'ZoomIn',
          'ZoomOut',
          'OriginalZoom',
        ],
      },
      {
        name: 'Livestream Info',
        id: 'livestreamInfo',
        items: [
          'Live',
          'StreamStartingTitle',
          'StreamStartingDescription',
          'StreamInterruptedTitle',
          'StreamInterruptedDescription',
        ],
      },
      {
        name: 'Ads Settings',
        id: 'adSettings',
        items: [
          'SimultaneousAds',
          'AdAnnouncement',
        ],
      },
      {
        name: 'ChromeCast',
        id: 'chromeCast',
        items: [
          'ChromeCastPlayingOn',
          'Dismiss',
        ],
      },
      {
        name: 'Error, Status & Privacy',
        id: 'errorStatusPrivacy',
        items: [
          'PlaybackErrorTitle',
          'PlaybackErrorDescription',
          'UnpublishedTitle',
          'UnpublishedDescription',
          'PrivateTitle',
          'PrivateDescription',
        ],
      },
      {
        name: 'Restrictions',
        id: 'restrictions',
        items: [
          'GeoBlockedTitle',
          'GeoBlockedDescription',
          'PaywallRestrictedHover',
          'PaywallRestrictedTitle',
          'PaywallRestrictedDescription',
          'PaywallRestrictedButton',
        ],
      },

    ]
  },

}
