// import Project from '@/models/projects/project'
// import organization from '@/store/account/organization/moduleOrganization'

export default {
  isLoading: true,
  isDropdownLoading: true,
  projects: [],
  pinnedProjects: [],
  unpinnedProjects: [],
  projectOnEdit: {
    organizationId: '',
    name: '',
    biskoId: '',
    prebidConfigId: '',
    chromeCastApplicationId: '',
    drmLicenseUrl: '',
    fpsCertificateUrl: '',
    widevineLicenseUrl: '',
    encodingTemplateId: '',
    releaseChannelId: '',
    playerUrl: '',
    subtitlesEnabledByDefault: false,
  },
  projectsOfUser: [],
  project: {
    // id: 1,
    name: '',
    // organizationId: 3,
  },
  // videoCatalogUrl: '',

  activeProjectId: '',
  activeProject: null,
  activeProjectPage: 1,
  customScreens: [],
  encodingTemplates: [],
  releaseChannel: [],

  filters: {
    params: {
      organizationId: '',
      page: 1,
      take: 10,
      search: '',
      order: 'asc',
    },
  },
  dropdownFilters: {
    params: {
      organizationId: '',
      page: 1,
      take: 5,
      search: '',
      order: 'asc',
    },
  },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  isSidebarCollapsed: false,
}
