export default {
  getIsLoading: state => state.isLoading,
  getName: state => state.activeProject.name,
  getProjectBiskoId: state => state.activeProject.biskoId,
  getProjectPrebidConfigId: state => state.activeProject.prebidConfigId,
  getDescription: state => state.project.description,
  getActiveProjectId: state => state.activeProjectId,
  getActiveProject: state => state.activeProject,
  getActiveProjectPage: state => state.activeProjectPage,
  getOptions: state => state.project.options,
  getProjects: state => state.projects,
  getPinnedProjects: state => state.pinnedProjects,
  getUnpinnedProjects: state => state.unpinnedProjects,
  getProjectsOfUser: state => state.projectsOfUser,
  getAllProjectsOfUser: state => state.projects,
  getVideoCatalogUrl: state => `${process.env.VUE_APP_API_BASE_URL}api/projects/${state.activeProjectId}/videos/catalog`,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
  getSubtitlesEnabledByDefault: state => state.projectOnEdit.subtitlesEnabledByDefault,

  getProjectOnEditName: state => state.projectOnEdit.name,
  getProjectOnEditBiskoId: state => state.projectOnEdit.biskoId,
  getProjectOnEditPrebidConfigId: state => state.projectOnEdit.prebidConfigId,
  getProjectOnEditChromeCastApplicationId: state => state.projectOnEdit.chromeCastApplicationId,
  getProjectOnEditDrmLicenseUrl: state => state.projectOnEdit.drmLicenseUrl,
  getProjectOnEditFpsCertificateUrl: state => state.projectOnEdit.fpsCertificateUrl,
  getProjectOnEditWidevineLicenseUrl: state => state.projectOnEdit.widevineLicenseUrl,
  getProjectCustomScreens: state => state.customScreens,
  getEncodingTemplates: state => state.encodingTemplates,
  getProjectOnEditEncodingTemplateId: state => state.projectOnEdit.encodingTemplateId,
  getEncodingTemplateName: state => state.encodingTemplates.find(template => template.id === state.activeProject.encodingTemplateId)?.name,
  getReleaseChannel: state => state.releaseChannel,
  getProjectOnEditPlayerUrl: state => state.projectOnEdit.playerUrl,
  getProjectOnEditReleaseChannelId: state => state.projectOnEdit.releaseChannelId,
  getPlayerVersionName: state => state.releaseChannel.find(r => r.id === state.activeProject.releaseChannelId)?.name,
}
